

export const actionSetUser = (user) => ({
	type: 'SET_USER',
	user: user,
});

export const actionSetUsers = (users) => ({
	type: 'SET_USERS',
	users: users
});

