/*import { createStore, combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form"
});
const store = (window.devToolsExtension
  ? window.devToolsExtension()(createStore)
  : createStore)(reducer);

export default store;
*/

//
// Redux init
// 
import { createStore, applyMiddleware, combineReducers } from 'redux'
//import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk';

import reducerMain from './reducer/reducerMain'
import reducerUser from "./reducer/reducerUser";
import reducerLogin from "./reducer/reducerLogin";

const rootReducer = combineReducers({
  main: reducerMain,
  login: reducerLogin,
  user: reducerUser,
});
const store = createStore(
	rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;

