import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  Image,
  Dropdown,
  Menu,
  Segment,
  Visibility,
  Icon,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import { actionSetScenario } from '../../../redux/actions/actionMain';
import store from '../../../redux/store';

import styles from './DashboardContainer.module.scss';

const SegmentStyled = styled(Segment)`
  minheight: 100px;
  padding: 1em 0em;
  text-align: center;
`;

const LoginInfo = (props) => {
  return (
    <Dropdown
      text={props.login && props.login.name}
      button
      className="icon"
      labeled
      icon="user"
    >
      <Dropdown.Menu>
        <Dropdown.Header
          content={'User: ' + (props.login && props.login.name)}
        />
        <Dropdown.Divider />
        <Dropdown.Item
          value={1}
          icon="log out"
          text="Logout"
          onClick={() => {
            props.onLogout();
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const menuStyle = {
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  marginBottom: '1em',
  marginTop: '0em',
  transition: 'box-shadow 0.5s ease, padding 0.5s ease',
};

const fixedMenuStyle = {
  border: '0px solid #ddd',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
};

class DashboardContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children, scenario,vm } = this.props;
    const { fixed } = this.state;

    //		<Responsive {...Responsive.onlyComputer}>
    return (
      <div style={{ height: 'calc(100% - 100px)' }}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <SegmentStyled vertical>
            <Menu
              style={fixed ? fixedMenuStyle : menuStyle}
              fixed={fixed ? 'top' : null}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
            >
              <Menu.Item header position="left"></Menu.Item>
              <Menu.Item
                header
                position="left"
                active={scenario === 'bahn'}
                onClick={() => {
                  store.dispatch(actionSetScenario('bahn'));
                }}
              >
                <Icon name="train" />
                Bahn
              </Menu.Item>
              <Menu.Item
                header
                position="left"
                active={scenario === 'terminal'}
                onClick={() => {
                  store.dispatch(actionSetScenario('terminal'));
                }}
              >
                <Icon name="plane" />
                Terminal
              </Menu.Item>
              <Menu.Item
                header
                position="left"
                active={scenario === 'web'}
                onClick={() => {
                  store.dispatch(actionSetScenario('web'));
                }}
              >
                <Icon name="bus" />
                KVB
              </Menu.Item>

              <Menu.Item header position="left"></Menu.Item>

              <Menu.Item position="right">
              </Menu.Item>
            </Menu>
          </SegmentStyled>
        </Visibility>

        {children}
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
DashboardContainer = connect(
  (state) => ({
    login: state.login.login,
    scenario: state.main.scenario,
    vm: state.main.vm,
  }),
  {} // bind account loading action creator
)(DashboardContainer);

export default withRouter(DashboardContainer);
