import React, { Component } from 'react';
import { Button, Header, Form, Grid, Message, Icon } from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';

import styles from './Login.module.scss';

import RestAPI from '../../../RestAPI';
import {
  actionSetApiBase,
  actionSetDevice,
  actionSetScenario,
} from '../../../redux/actions/actionMain';
import store from '../../../redux/store';

const iconError = 'exclamation triangle';
const iconCheck = 'circle notched';
const iconOk = 'check';

const CopyrightText = () => (
  <div>
    © Charamel GmbH –{' '}
    <a target="_blank" href="https:/charamel.de">
      www.charamel.de
    </a>
  </div>
);

const styleButtonActive = { backgroundColor: '#013378', color: '#ffffff' };
const styleButtonInActive = { backgroundColor: '#888888', color: '#ffffff' };

const NoMobile = () => (
  <div>
    <Header as="h1">Demonstrator</Header>
    <br />
    Mobile wird noch nicht unterstützt
    <br />
  </div>
);

const ViewForm = (props) => (
  <div>
    <Header as="h1">Demonstrator</Header>
    <Form size="large">
      <Form.Input
        fluid
        icon="user"
        iconPosition="left"
        placeholder="Login-Name"
        onChange={(d, e) => props.onChange('user', e.value)}
      />
      <Form.Input
        fluid
        icon="lock"
        iconPosition="left"
        placeholder="Passwort"
        type="password"
        onChange={(d, e) => props.onChange('passwd', e.value)}
      />
      <Button
        color={'orange'}
        disabled={!props.active}
        fluid
        size="large"
        onClick={() => props.onLogin()}
      >
        Login
      </Button>
      <Message
        hidden={props.message === ''}
        icon
        negative={props.icon === iconError}
        positive={props.icon === iconOk}
      >
        <Icon name={props.icon} loading={props.icon === iconCheck} />
        <Message.Content>
          <Message.Header>{props.message}</Message.Header>
        </Message.Content>
      </Message>
    </Form>
  </div>
);

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      passwd: '',
      message: '',
      showIcon: false,
      showMessage: '',
      buttonactive: true,
    };
  }

  onChange(type, data) {
    switch (type) {
      case 'user':
        this.setState({ user: data });
        break;
      case 'passwd':
        this.setState({ passwd: data });
        break;
    }
  }

  onLogin() {
    this.setState({
      showIcon: iconCheck,
      buttonactive: false,
      showMessage: 'Verify',
    });
    RestAPI.login(this.state.user, this.state.passwd)
      .then((result) => {
        if (result && result.ok) {
          store.dispatch(actionSetScenario('bahn'));
          this.props.history.push('/');
        } else {
          console.error('invalid user or password');
          this.setState({
            showMessage: 'invalid user or password',
            showIcon: iconError,
            buttonactive: true,
          });
        }
      })
      .catch((err) => {
        this.setState({ showProgress: false });
        console.error(err);
        this.setState({
          showMessage: 'Connection Error',
          showIcon: iconError,
          buttonactive: true,
        });
      });
  }

  render() {
    const { mobile } = this.props;

    return (
      <div className={styles.main}>
        <Grid
          columns={3}
          verticalAlign={'middle'}
          textAlign="center"
          style={{ height: '100%' }}
        >
          <Grid.Column textAlign="center" style={{ width: 400 }}>
            <img
              style={{ width: '100%' }}
              src={
                process.env.PUBLIC_URL +
                '/assets/cropped-avasag_logo_orange-1000.png'
              }
              alt="vm"
            />
            {mobile ? (
              <NoMobile />
            ) : (
              <ViewForm
                onChange={(type, data) => {
                  this.onChange(type, data);
                }}
                onLogin={() => {
                  this.onLogin();
                }}
                message={this.state.showMessage}
                icon={this.state.showIcon}
                active={this.state.buttonactive}
              />
            )}
          </Grid.Column>
        </Grid>
        <div className={styles.footer}>
          <CopyrightText />
        </div>
      </div>
    );
  }
}

export default withRouter(LoginForm);
