import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './Scene.module.scss';

class Scene extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {}


  render() {
    const { } = this.state;
    const {  } = this.props;


    return (
      <div className={styles.main}>
      </div>
    );
  }
}

Scene = connect(
  (state) => ({
    login: state.login.login,
    apibase: state.main.apibase,
    device: state.main.device,
  }),
  {} // bind account loading action creator
)(Scene);

export default Scene;
