import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Progress, Button, Icon, Checkbox, Dropdown } from 'semantic-ui-react';

import Login from './../Landing/Login.js';

import styles from './MainPage.module.scss';
import Ratio from 'react-ratio';

import {
  actionSetApiBase,
  actionSetDevice,
  actionSetScenario,
} from '../../../redux/actions/actionMain';
import store from '../../../redux/store';

import RestAPI from '../../../RestAPI.js';
import { actionSetLogin } from '../../../redux/actions/actionLogin.js';

import Sidebar from '../Sidebar/Sidebar';
import Container from '../Container/Container';
import DashboardContainer from '../DashboardContainer/DashboardContainer.js';

const MobileDetect = require('mobile-detect');
const md = new MobileDetect(window.navigator.userAgent);

/**** MainPage ***************************************************************/
/*!
 **
 ******************************************************************************/
class MainPage extends Component {
  constructor(props) {
    super(props);

    let title = 'Avasag Demonstrator';

    if (process.env.NODE_ENV !== 'production') {
      title += ' ' + process.env.NODE_ENV;
    }

    this.state = {
      loggingMessage: '',
      mode: 'init',
      title: title,
    };

    store.dispatch(
      actionSetDevice({
        mobile: md.mobile(),
        tablet: md.tablet(),
        userAgent: md.userAgent(),
        os: md.os(),
      })
    );
  }

  componentDidMount() {
    RestAPI.init().then(() => {
      if (this.props.device.mobile) {
        this.props.history.push('/login');
        return;
      }

      store.dispatch(actionSetApiBase(RestAPI.getBase()));
      RestAPI.authStatus().then((result) => {
        if (true) {
          //result && result.ok) {
          store.dispatch(actionSetLogin({}));

          store.dispatch(actionSetScenario('bahn'));
          this.props.history.push('/');
        } else {
          this.props.history.push('/login');
        }
        this.setState({ mode: 'main ' });
      });
    });
  }

  onLogout() {
    RestAPI.logout();
    this.props.history.push('/login');
  }

  render() {
    const { login, device } = this.props;
    const { mode } = this.state;

    return (
      <div className={styles.main}>
        <Helmet>
          <title>{this.state.title}</title>
        </Helmet>
        {mode === 'loading....' ? (
          <div>init</div>
        ) : (
          <Switch>
            {login && (
              <DashboardContainer
                onLogout={() => {
                  this.onLogout();
                }}
              >
                <Route
                  path="/"
                  render={(props) => (
                    <div className={styles.flexcontainer}>
                      <div className={styles.flexitemsSide}>
                        <Sidebar />
                      </div>
                      <div className={styles.flexitemsMain}>
                        <Ratio ratio={16 / 9}>
                          <Container />
                        </Ratio>
                        <div className={styles.fillit}></div>
                      </div>
                    </div>
                  )}
                />
              </DashboardContainer>
            )}
            <Route
              path="/login"
              render={(props) => <Login mobile={device && device.mobile} />}
            />
          </Switch>
        )}
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
MainPage = connect(
  (state) => ({
    login: state.login.login,
    device: state.main.device,
    vm: state.main.vm,
  }),
  {} // bind account loading action creator
)(MainPage);

export default withRouter(MainPage);
