

export const actionSetVM = (vm) => ({
	type: 'SET_VM',
	vm: vm
});

export const actionSetStyle = (style) => ({
	type: 'SET_STYLE',
	style: style
});

export const actionSetDevice = (device) => ({
	type: 'SET_DEVICE',
	device: device
});

export const actionSetPath = (activePath) => ({
	type: 'SET_PATH',
	activePath: activePath
});

export const actionSetDebugMode = (debugMode) => ({
	type: 'SET_DEBUG_MODE',
	debugMode: debugMode
});

export const actionSetApiBase = (apibase) => ({
	type: 'SET_APIBASE',
	apibase: apibase
});

export const actionSetScenario = (scenario) => ({
	type: 'SET_SCENARIO',
	scenario: scenario
});

export const actionSetViewport = (viewport) => ({
	type: 'SET_VIEWPORT',
	viewport: viewport
});