import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Segment, Message, Icon } from 'semantic-ui-react';

import styles from './SceneWeb.module.scss';
import { cx } from '../../../helpers/classNames.helper';

class SceneWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const {} = this.state;
    const { sticky, onClose, viewport } = this.props;

    return (
      <div className={styles.main}>
        <div className={cx(sticky ? styles.segmenthidden : styles.segment)}>
          <div style={{ height: '100%' }} color={'grey'}>
            <div
              className={cx(
                viewport.transparent
                  ? styles.contentTransparent
                  : styles.content,
                viewport.transparent ? undefined : styles.segmentBorder
              )}
            ></div>
            <div className={styles.close}>
              {!sticky && (
                <Button
                  icon
                  style={{ zIndex: '1000' }}
                  onClick={() => {
                    onClose();
                  }}
                >
                  <Icon name={'close'} color={'red'} />
                </Button>
              )}
            </div>
          </div>
        </div>
        <iframe className={styles.iframe} src="https://www.kvb.koeln/"></iframe>
      </div>
    );
  }
}

SceneWeb = connect(
  (state) => ({
    login: state.login.login,
    apibase: state.main.apibase,
    device: state.main.device,
    viewport: state.main.viewport,
  }),
  {} // bind account loading action creator
)(SceneWeb);

export default SceneWeb;
