import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './SceneBahn.module.scss';

class SceneBahn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    const {} = this.state;
    const { style } = this.props;

    const backgroundURL = `${process.env.PUBLIC_URL}/assets/demonstrator_website_background_02.png`;
    return (
      <div
        className={styles.main}
        style={{
          backgroundImage: `url(${backgroundURL})`,
        }}
      ></div>
    );
  }
}

SceneBahn = connect(
  (state) => ({
    login: state.login.login,
    apibase: state.main.apibase,
    device: state.main.device,
    style: state.main.style,
  }),
  {} // bind account loading action creator
)(SceneBahn);

export default SceneBahn;
