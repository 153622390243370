import React, { Component } from 'react';
import {
  Progress,
  Header,
  Form,
  Grid,
  Message,
  Icon,
  Button,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import Script from 'react-load-script';

import { withRouter } from 'react-router-dom';

import styles from './Container.module.scss';

import RestAPI from '../../../RestAPI';
import { includesOneOf } from '../../../helpers/scopecheck';

import { actionSetVM } from '../../../redux/actions/actionMain';
import store from '../../../redux/store';

import Scene from '../Scene/Scene';
import SceneBahn from '../SceneBahn/SceneBahn';
import SceneWeb from '../SceneWeb/SceneWeb';
import SceneTerminal from '../SceneTerminal/SceneTerminal';
import { cx } from '../../../helpers/classNames.helper';

const sbKey = 'B5HFY-5K6DL-DYU78-ECSJH';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0.001,
      loading: true,
      sticky: true,
      blending: false,
      blendingShow: false,
      currentScenario: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      (this.props.scenario && nextProps.scenario !== this.props.scenario) ||
      !this.state.currentScenario
    ) {
      console.log('### scenario', nextProps.scenario);
      this.setState({ sticky: true, blending: true, blendingShow: true });

      setTimeout(() => {
        switch (nextProps.scenario) {
          case 'bahn':
            this.props.vm.cameras.setByName('bahn', { attack: 0 });
            break;
          case 'terminal':
            this.props.vm.cameras.setByName('terminal', { attack: 0 });
            break;
          case 'web':
            this.props.vm.cameras.setByName('web', { attack: 0 });
            break;
        }

        this.setState({
          currentScenario: nextProps.scenario,
        });
      }, 1000);
      setTimeout(() => {
        this.setState({ blending: false });
      }, 1200);
      setTimeout(() => {
        this.setState({ blendingShow: false });
      }, 1200 + 300);
    }
  }

  handleScriptCreate() {}

  handleScriptError() {
    this.setState({
      scriptError: true,
    });
  }

  handleScriptLoad() {
    this._vuppetmaster = new window.VM.Vuppetmaster({});
    document._vm = this._vuppetmaster;
    store.dispatch(actionSetVM(this._vuppetmaster));

    this._vuppetmaster.on('complete', async () => {
      this.setState({
        progress: null,
      });

      this.setState({ loading: false });
    });

    this._vuppetmaster.on('progress', (data) => {
      this.setState({ progress: data.value * 100 });
    });
    this._vuppetmaster.start({
      app: 'avasag-demonstrator'
    }
    );
  }

  render() {
    const { scenario, login, apibase, style } = this.props;
    const { progress, sticky, blending, blendingShow, currentScenario } =
      this.state;

    let vmurl = login && RestAPI.getVM() + '/api?key=' + sbKey;

    let styleScenario = '';
    switch (currentScenario) {
      case 'bahn':
      case 'terminal':
        styleScenario = currentScenario;
        break;
      case 'web':
        if (sticky) {
          styleScenario = 'webhidden';
        } else {
          styleScenario = 'web';
        }

        break;
    }

    return (
      <div
        className={styles.main}
        style={{
          backgroundColor: style ? 'grey' : 'grey',
        }}
      >
        {apibase && login && (
          <Script
            url={vmurl}
            onCreate={this.handleScriptCreate.bind(this)}
            onError={this.handleScriptError.bind(this)}
            onLoad={this.handleScriptLoad.bind(this)}
          />
        )}
        {progress && (
          <Progress
            style={{ position: 'absolute', width: '100%', zIndex: '500' }}
            percent={progress}
            color={'orange'}
            size="tiny"
            active
          ></Progress>
        )}
        {blendingShow && (
          <div className={blending ? styles.blendin : styles.blendout}></div>
        )}
        {currentScenario === 'bahn' && <SceneBahn />}
        {currentScenario === 'terminal' && <SceneTerminal />}
        {currentScenario === 'web' && (
          <div>
            {sticky && (
              <div className={styles.sticky}>
                <Button
                  onClick={() => {
                    this.setState({ sticky: !sticky });
                  }}
                >
                  <div>
                    <img
                      className={styles.help}
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/cropped-avasag_logo_orange_512.png'
                      }
                    />
                  </div>
                </Button>
              </div>
            )}
            <SceneWeb
              sticky={sticky}
              onClose={() => {
                this.setState({ sticky: !sticky });
              }}
            />
          </div>
        )}
        <div
          id="webglcontainer"
          className={cx(styles.renderview, styles[styleScenario], styles.show)}
        ></div>
      </div>
    );
  }
}

Container = connect(
  (state) => ({
    login: state.login.login,
    apibase: state.main.apibase,
    scenario: state.main.scenario,
    style: state.main.style,
    vm: state.main.vm,
  }),
  {} // bind account loading action creator
)(Container);

export default withRouter(Container);
