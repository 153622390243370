const initialState = {
  activePath: '',
  apibase: null,
  debugMode: false,
  device: {},
  scenario: '',
  style: false,
  vm: null,
  viewport: {},
};

const reducerMain = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VM':
      return {
        ...state,
        vm: action.vm,
      };
    case 'SET_STYLE':
      return {
        ...state,
        style: action.style,
      };
    case 'SET_APIBASE':
      return {
        ...state,
        apibase: action.apibase,
      };
    case 'SET_DEVICE':
      return {
        ...state,
        device: action.device,
      };
    case 'SET_PATH':
      return {
        ...state,
        activePath: action.activePath,
      };
    case 'SET_DEBUG_MODE':
      return {
        ...state,
        debugMode: action.debugMode,
      };
    case 'SET_SCENARIO':
      return {
        ...state,
        scenario: action.scenario,
      };
    case 'SET_VIEWPORT':
      return {
        ...state,
        viewport: action.viewport,
      };
    default:
      return state;
  }
};

export default reducerMain;
