import React, { Component } from 'react';
import {
  Button,
  Message,
  Checkbox,
  Form,
  Segment,
  Icon,
  Label,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import styles from './Sidebar.module.scss';
import {
  actionSetStyle,
  actionSetViewport,
} from '../../../redux/actions/actionMain';
import store from '../../../redux/store';

import { arrayDropDown } from '../../../helpers/arrayDropDown';

const animations = [
  {
    name: 'Satz 1',
    ani: 'satz1',
    text: 'Der Zug nach Hannover fährt auf Gleis 8.',
  },
  { name: 'Satz 2', ani: 'satz2', text: 'Heute kein Halt in Köln.' },
  {
    name: 'Satz 3',
    ani: 'satz3',
    text: 'Die vorausslichtliche Ankunftszeit in Erfurt ist 8:24 Uhr.',
  },
  {
    name: 'Satz 4',
    ani: 'satz4',
    text: 'Der Zug hat leider eine Verspätung von 15 Minuten. Er fährt voraussichtlich um 13 Uhr.',
  },
];

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sentence: '',
    };
  }

  onToggleScene() {
    const casual = !this.props.style;
    store.dispatch(actionSetStyle(casual));
    this.props.vm.core.playSequenceByName(
      casual ? 'casual_seq' : 'business_seq'
    );

    setTimeout(() => {
      this.setCameraByScenario(this.props.scenario);
    }, 10);
  }

  onToggleTransparent() {
    const viewport = {
      ...this.props.viewport,
      transparent: !this.props.viewport.transparent,
    };
    store.dispatch(actionSetViewport(viewport));
  }

  setCameraByScenario(scenario) {
    if (scenario === 'bahn') {
      this.props.vm.cameras.setByName('bahn', { attack: 0 });
    } else if (scenario === 'terminal') {
      this.props.vm.cameras.setByName('terminal', { attack: 0 });
    } else {
      this.props.vm.cameras.setByName('web', { attack: 0 });
    }
  }

  onPlay(entry) {
    if (entry) {
      this.props.vm.core.playAnimationByName(entry.ani);
      this.setCameraByScenario(this.props.scenario);
    }
  }

  render() {
    const opts = arrayDropDown(animations.map((entry) => entry.name));

    const { sentence } = this.state;
    const { style, viewport, scenario } = this.props;

    return (
      <div className={styles.main}>
        <br />
        <div style={{ width: '80%' }}>
          <img
            style={{ width: '100%' }}
            src={
              process.env.PUBLIC_URL +
              '/assets/cropped-avasag_logo_orange-1000.png'
            }
            alt="vm"
          />
        </div>
        <Segment>
            {animations.map((entry) => (
              <div key={entry.name} className={styles.button}>
                <Button
                  icon
                  labelPosition="right"
                  fluid
                  style={{ height: '100%' }}
                  onClick={() => {
                    this.onPlay(entry);
                  }}
                >
                  <div className={styles.buttonText}> {entry.text}</div>
                  <Icon name="play" />
                </Button>
              </div>
            ))}
          <br />
          <Message>
            <Message.Header>Style</Message.Header>
            <br />
            <Form>
              <Form.Field>
                <Checkbox
                  radio
                  label="Business"
                  name="checkboxRadioGroup"
                  checked={!style}
                  onChange={() => {
                    this.onToggleScene();
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  radio
                  label="Casual"
                  name="checkboxRadioGroup"
                  checked={style}
                  onChange={() => {
                    this.onToggleScene();
                  }}
                />
              </Form.Field>
            </Form>
          </Message>
          {scenario === 'web' && (
            <Message>
              <Message.Header>Overlay</Message.Header>
              <br />
              <Form>
                <Form.Field>
                  <Checkbox
                    label="Transparent"
                    name="checkboxRadioGroup"
                    checked={viewport.transparent ? true : false}
                    onChange={() => {
                      this.onToggleTransparent();
                    }}
                  />
                </Form.Field>
              </Form>
            </Message>
          )}
        </Segment>
      </div>
    );
  }
}

Sidebar = connect(
  (state) => ({
    login: state.login.login,
    apibase: state.main.apibase,
    device: state.main.device,
    style: state.main.style,
    scenario: state.main.scenario,
    vm: state.main.vm,
    viewport: state.main.viewport,
  }),
  {} // bind account loading action creator
)(Sidebar);

export default withRouter(Sidebar);
