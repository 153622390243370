import store from './redux/store';
import { actionSetLogin } from './redux/actions/actionLogin';
import { actionSetUser } from './redux/actions/actionUser';

// Init: check backend
var API_ROOT = null;
var API_VM = null;
var API_BASE = null;

let server = 'https://vuppetmaster.de';
let local = 'http://localhost';

export const getVM = () => {
  return API_VM;
};

export const getBase = () => {
  return API_BASE;
};

export const init = async () => {
  const hostname = window && window.location && window.location.hostname;
  console.log('init hostname ', hostname);

  if (process.env.NODE_ENV === 'production') {
    API_VM = 'https://vuppetmaster.de/vmapi/v4';
  } else {
    API_VM = 'http://' + hostname + ':9443/vmapi/v4.local';
  }

  if (
    process.env.NODE_ENV === 'production' ||
    hostname.endsWith('vuppetmaster.de')
  ) {
    API_ROOT = server + '/api_vmadmin';
    API_BASE = server;
  } else {
    API_ROOT = local + ':8443/api_vmadmin';

    API_BASE = local;
  }
  console.log('API_ROOT = ', API_ROOT);
  console.log('API_VM = ', API_VM);
};

const post = async (url, data) => {
  console.log('post ', url);

  return await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
    mode: 'cors',
  })
    .then((response) => {
      if (!response) return null;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response
          .text()
          .then((text) => ({
            ok: false,
            status: response.status,
            error: text,
          }));
      }
    })
    .catch((e) => {
      return { ok: false, error: e.message };
    });
};

const postForm = async (url, data) => {
  console.log('postForm ', url);

  return await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
    body: data,
    mode: 'cors',
  }).then((response) => {
    if (!response) return null;
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    } else {
      return response
        .text()
        .then((text) => ({ ok: false, status: response.status, error: text }));
    }
  });
};

const get = async (url, queryData) => {
  console.log('get ', url);

  const query = queryData
    ? '?' +
      Object.keys(queryData)
        .map(
          (k) => encodeURIComponent(k) + '=' + encodeURIComponent(queryData[k])
        )
        .join('&')
    : '';
  return await fetch(url + query, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    mode: 'cors',
  })
    .then((response) => {
      if (!response) return null;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response.text().then((text) => ({ ok: false, error: text }));
      }
    })
    .catch((e) => {
      return { ok: false, error: e.message };
    });
};

export const graphQL = async (query) => {
  let result = post(API_ROOT + '/graphql', { query });
  return result;
};

export const toGraphQL = (object, addBrackets = true) => {
  if (!object) {
    return object;
  }

  const toString = (value) => {
    switch (typeof value) {
      case 'string':
        return `"${value}"`;
      case 'number':
        return value + '';
      case 'object':
        return toGraphQL(value);
      default:
        return value;
    }
  };

  if (Array.isArray(object) && object.length !== undefined) {
    const values = object.map((value) => toString(value)).join(', ');
    return addBrackets ? `[${values}]` : values;
  } else {
    const values = Object.keys(object)
      .filter((key) => object[key] !== undefined)
      .map((key) => key + ':' + toString(object[key]))
      .join(', ');

    return addBrackets ? `{${values}}` : values;
  }
};

// AUTHENTICATION

const scope = ['avasag'];
const includesAllOf = (scope, needs) => {
  let count = 0;
  if (scope && scope.length > 0) {
    for (let i = 0; i < needs.length; i++) {
      if (scope.indexOf(needs[i]) >= 0) {
        count++;
      }
    }
  }
  return count === needs.length;
};

export const login = async (name, passwd) => {
  const result = await post(API_ROOT + '/auth/login', {
    name,
    passwd,
    scope: scope,
  });
  if (result && result.ok && result.scope) {
    if (includesAllOf(result.scope, scope)) {
      store.dispatch(actionSetLogin(result));
      return result;
    }
  }
  return null;
};

export const logout = async () => {
  const result = await get(API_ROOT + '/auth/logout');
  if (result && result.ok) {
    store.dispatch(actionSetLogin(null));
  }
  return result;
};

export const authStatus = async () => {
  const result = await get(API_ROOT + '/auth/status');
  if (result && result.ok && result.scope) {
    if (includesAllOf(result.scope, scope)) {
      store.dispatch(actionSetLogin(result));
      return result;
    }
  }
  return null;
};

// USER
export const user = async (uuid) => {
  const result = await graphQL(
    `{users(filter: {uuid: {EQ: "${uuid}"}}) { name, uuid, email, projects, customer_id } }`
  );
  const user =
    result && result.data && result.data.users && result.data.users[0];
  if (user) {
    store.dispatch(actionSetUser(user));
  }
  return user;
};

// MODELS
export const getModels = async () => {
  const result = await get(API_ROOT + '/model/getall');
  return result;
};

// MOTIONS
export const getMotions = async () => {
  const result = await get(API_VM + '/assets/resources', { name: 'motions' });
  return result;
};

export default {
  post,
  get,
  graphQL,

  init,
  login,
  logout,
  authStatus,

  user,
  getModels,
  getMotions,

  getVM,
  getBase,
};
